@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";

//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";

// STYLE SALEBRAND
@import "../sass/sections/salebrand/salebrand_style";

// STYLE PECEP
@import "../sass/sections/pecep/home/pecep_intro_home";
@import "../sass/sections/pecep/home/pecep_quem_somos";
@import "../sass/sections/pecep/home/pecep_history";
@import "../sass/sections/pecep/home/pecep_virtual";
@import "../sass/sections/pecep/home/pecep_time";
@import "../sass/sections/pecep/home/pecep_ajuda";
@import "../sass/sections/pecep/home/pecep_destaque";
@import "../sass/sections/pecep/home/pecep_parcerias";
@import "../sass/sections/pecep/home/pecep_footer";
@import "../sass/sections/pecep/home/big_numbers";

//style PDF
@import "../sass/sections/pecep/home/teste_pdf";
@import "../sass/sections/pecep/home/pdf_percentual";
@import "../sass/sections/pecep/home/pdf_semanal_turmas";
@import "../sass/sections/pecep/home/pdf_mensal_turmas";

//Comingsoon
@import "../sass/sections/pecep/home/coming_soon";

///Teste
@import "../sass/sections/pecep/home/pecep_teste";

// STYLE PECEP QUEM SOMOS
@import "../sass/sections/pecep/quem-somos/pecep_intro_quem_somos";
@import "../sass/sections/pecep/quem-somos/pecep_quem_somos_2";
@import "../sass/sections/pecep/quem-somos/pecep_nossa_historia";
@import "../sass/sections/pecep/quem-somos/pecep_ingresso";

// STYLE PECEP CADASTRO DE ALUNOS
@import "../sass/sections/pecep/selecao-alunos/pecep_intro_selecao_alunos";
@import "../sass/sections/pecep/selecao-alunos/pecep_formulario_cadastro_aluno";

// STYLE PECEP CADASTRO DE PROFESSORES
@import "../sass/sections/pecep/selecao-professores/pecep_intro_selecao_professores";
@import "../sass/sections/pecep/selecao-professores/pecep_formulario_cadastro_professor";

// STYLE PECEP CONTRIBUIÇÃO
@import "../sass/sections/pecep/contribuicao/pecep_intro_contribuicao";
@import "../sass/sections/pecep/contribuicao/pecep_como_contribuir";
@import "../sass/sections/pecep/contribuicao/pecep_redes_sociais";

// STYLE PECEP VIRTUAL
@import "../sass/sections/pecep/pecep-virtual/pecep_intro_pecep_virtual";
@import "../sass/sections/pecep/pecep-virtual/pecep_download_materiais";

// STYLE COMPONENTES  
@import "../sass/sections/pecep/componentes/midia";

// STYLE CHAMADA  
@import "../sass/sections/pecep/chamada/login_chamada";
@import "../sass/sections/pecep/chamada/chamada";
@import "../sass/sections/pecep/chamada/modal_chamada";

// PÁGINAS
main {
  overflow: hidden;
}

.intro-home, #intro-pecep{
  margin-top: 8.75rem;
  @include d(md){
    margin-top: 100px;
  }
}

.itens-rigth-wrapper{
  .video{
    max-height: 244px;
    height: 100%;
    position: relative;

    @include d(md){
      max-height: 100%;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after{
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.06);
        backdrop-filter: blur(2px);
    }

    .icon-youtube{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 80px;
        height: 80px;
    }
  }
}

// #prestacao-contas {
//   .container {
//     .btn{
//       padding: 8px 16px !important;
//       width:max-content !important;
//       margin-top: 0 !important;
//       &:after{
//         bottom: -3px;
//         right: -3px;
//       }
//     }
//   }
  
//   .titulo {
//     font-size: 56px;
//     font-weight: 300;
//     @include d(md2){
//       font-size: 2rem;
//     }
//   }

//   .accordion-section {
//     margin-top: 56px;
//     margin-bottom: 7.5rem;
//   }
  
 

//   .accordion {
//     .accordion-item {
//       @include Botao();
//       .btn{
//         padding: 8px 16px !important;
//         width:max-content !important;
//         margin-top: 0 !important;
//         &:after{
//           bottom: -3px;
//           right: -3px;
//         }
//       }
//       .accordion-title {
//         border: 1px solid rgba(14, 15, 15, 0.05);
//         display: flex;
//         padding: 32px;
//         width: 100%;
//         cursor: pointer;
//         transition: 0.3s ease;
//         display: flex;
//         align-items: center;
//         transition: 0.3s ease;
//         svg {
//           width: 24px;
//           height: 24px;
//           margin-left: auto;
//           border-radius: 50%;
//         }

//         &:hover {
//           background: rgba(0, 0, 0, 0.05);
//         }
//       }
//       &.active {
//         .accordion-title svg {
//           background: #625fa8;
//           transform: rotate(180deg);
//           --cor-2: white;
//         }
//       }
//     }
//     .accordion-content {
//       height: 0;
//       overflow: hidden;
//     }
//     .accordion-container {
//       display: flex;
//       flex-direction: column;
//       gap: 24px;
//       padding: 32px;
//       border-left: 2px solid #625fa8;
//     }
//   }
// }
